import React from 'react';
import {RouterProvider} from 'react-router-dom';
import router from '@mgp-fe/designer/router/router.tsx';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Affiliate, Customer, Partner} from '@mgp-fe/shared/core-api/domain/user.ts';
import AuthContextProvider from '@mgp-fe/shared/modules/auth/state/AuthContextProvider.tsx';
import {cn} from '@mgp-fe/shared/utils';
import BackgroundTop from '@mgp-fe/shared/assets/background/background-hero.png';
import BackgroundBottom from '@mgp-fe/shared/assets/background/background-footer.png';
import NotificationsProvider from '@mgp-fe/shared/ui/notifications/NotificationsProvider.tsx';


export default function App() {

	const params = new URL(document.location as never).searchParams;
	const modal = params.has('modal');

	return <div className='w-screen h-screen bg-background z-[-100] overflow-x-hidden'>
		<div className={cn(
			'h-screen bg-smoke bg-cover bg-center flex flex-col items-center justify-center relative z-[0]')}>
			<div className='overflow-hidden absolute -z-20 w-screen bg-cover h-screen bg-no-repeat bg-top' style={{
				backgroundImage: !modal ? `url("${BackgroundTop}")` : '',
			}}/>
			<div
				className='overflow-hidden absolute opacity-20 -z-10 bottom-0 left-0 w-full bg-cover h-[50%] bg-no-repeat bg-bottom'
				style={{
					backgroundImage: !modal ? `url("${BackgroundBottom}")` : '',
				}}/>
			<div className={`flex w-screen h-screen ${modal ? '' : 'py-8'}`}>
				<QueryClientProvider client={queryClient}>
					<AuthContextProvider<Customer | Partner | Affiliate> loginModalMode='login_only'>
						<NotificationsProvider>
							<RouterProvider router={router}/>
						</NotificationsProvider>
					</AuthContextProvider>
				</QueryClientProvider>
			</div>
		</div>
	</div>;
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 5 * 1000,
			retry: 1,
		},
	},
});