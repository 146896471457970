import React, {useContext, useState} from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {FormProvider, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import useCreateMouthguardDesign from '@mgp-fe/shared/core-api/mutations/mouthguard-design/create.ts';
import DesignerContext from '@mgp-fe/designer/state/designer-context.ts';
import {CanvasData} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {useSearchParams} from 'react-router-dom';
import {useGetBase64FromContent} from '@mgp-fe/designer/hooks/get-base64-from-content.ts';
import {parseCanvasDataToOldFormat, parseColorsToOldData} from '@mgp-fe/designer/modules/common/utils';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import useCreateCustomerMutation from '@mgp-fe/shared/core-api/mutations/user/customer/create.ts';
import useUtilizeSignUpLinkMutation from '@mgp-fe/shared/core-api/mutations/signup-link/create-customer.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {AxiosResponse} from 'axios';
import routes from '@mgp-fe/designer/router/routes.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {DesignerDialog} from '@mgp-fe/designer/modules/common/ui/components';

export function SaveWithEmailForm() {
	const [query] = useSearchParams();
	const {user} = useAuth();
	const displayModal = useToggle({initialState: false});

	const designerContext = useContext(DesignerContext);
	const form = useForm<SaveWithEmailRequest>({
		resolver: zodResolver(CreateDesignFormSchema),
	});

	const [name, setName] = useState('');
	const createCustomerWithSignUpLinkMutation = useUtilizeSignUpLinkMutation();
	const createCustomerMutation = useCreateCustomerMutation();
	const createMouthguardMutation = useCreateMouthguardDesign({
		onSuccess: () => parent.postMessage('close-iframe', '*'),

	});

	const signUpLink = query.get('signUpLink');
	const contentImages = useGetBase64FromContent(designerContext.content);

	const onSubmit = async (d: SaveWithEmailRequest) => {
		const colors = parseColorsToOldData(designerContext.sectionColors);

		const canvasData = parseCanvasDataToOldFormat({
			content: designerContext.content,
			images: contentImages,
		});

		const createMouthguardData = {
			name: name ?? 'First Design',
			type: window.location.pathname.includes(routes.clearDesigner.index) ? 'clear_custom_design' : 'full_custom_design',
			canvasData: canvasData as never as CanvasData,
			colors: colors,
			texture: designerContext.screenshot.textureScreenshot as string,
			screenshot: designerContext.screenshot.sceneScreenshot as string,
			thickness: designerContext.thickness.thickness,
			publicAccess: 'read_access',
			customer: (query.has('teamId') || query.has('affiliateId')) ? undefined : user?.['@id'],
			team: query.get('teamId') ?? undefined,
			affiliate: query.get('affiliateId') ?? undefined,
		} as const;

		const onSuccess = (rs: AxiosResponse<Customer>) => {
			setTimeout(async () => {
				createMouthguardMutation.mutate(createMouthguardData, {
					onSuccess: () => {
						window.parent.postMessage({
							event: 'login',
							accessToken: rs.data.signToken,
						}, '*');
					}});
			}, 500);
		};

		if (signUpLink) {
			createCustomerWithSignUpLinkMutation.mutate({
				id: signUpLink,
				request: {
					user: {
						email: d.email,
					},
				},
			}, {
				onSuccess,
			});
		} else {
			createCustomerMutation.mutate({
				email: d.email,
			}, {
				onSuccess,
			});
		}
	};

	return <>
		<div className='flex flex-col md:flex-row gap-8 pt-20'>
			<input
				onChange={event => setName(event.target.value)}
				type='text'
				placeholder='DESIGN NAME'
				className='!w-fit mr-auto md:mr-0 ml-auto'/>
			<Button
				className='ml-auto md:ml-0 mr-auto'
				onClick={displayModal.toggle}>
			Save Design
			</Button>
		</div>
		<FormProvider {...form}>
			<DesignerDialog isOpen={displayModal.state} onClose={() => displayModal.off()}>
				<h2 className='max-w-[25rem] normal-case'>Let s send that design to you!</h2>
				<p className='text-muted max-w-[30rem] my-16'>Enter the information below so we can send you your design or order your design now! </p>
				<form
					onSubmit={form.handleSubmit(d => onSubmit(d))}
					className='horizontal-labels items-center gap-16 max-w-[30rem] mx-auto'>
					<TextInput type='email' label='Email Address' placeholder='Your email address...' name='email'/>
					<div className='flex justify-between mt-16'>
						<Button
							state={(createMouthguardMutation.status === 'loading' ||
					createCustomerMutation.status === 'loading' ||
					createCustomerWithSignUpLinkMutation.status === 'loading'
							) ? 'loading' : 'idle'}
							className='h-fit mx-auto px-20'>
						Save
						</Button>
					</div>
				</form>
			</DesignerDialog>
		</FormProvider>
	</>;
}

const CreateDesignFormSchema = z.object({
	email: z.string().email(),
});

type SaveWithEmailRequest = z.infer<typeof CreateDesignFormSchema>

